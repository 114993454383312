$font-family: "Myriad Pro", Myriad, "Myriad Web Pro", "PT Sans", sans-serif;
$background-color: #fff;

$container-background-color: #FFF;
$container-shadow: 0px 0px 5px #fff;

$header-background-color: #FFF;
$header-text-color: #4A4A4A;
$header-text-hover-color: #CCC;
$navbar-height: 100px;
$header-max-width: 1200px;

$accent-background-color: #4d65a1;
$accent-text-color: #FFF;
$bid-box-background-color: $header-background-color;
$bid-box-text-color: $header-text-color;
@keyframes bidflash {
  0% {
    color: #059416;
  }
  60% {
    color: #03640e;
  }
  100% {
    color: #000;
  }
}

$lot-tile-active-background: #aac3d8;
$lot-tile-active-border: 1px solid #337ab7;

$watch-color: #7d7d7d;
$watch-hover-color: #229e22;
$watch-watched-color: #229e22;
$watch-watched-hover-color: #229e22;

$btn-watch-color: #ffffff;
$btn-watch-hover-color: #019e01;
$btn-watch-watched-color: #019e01;
$btn-watch-watched-hover-color: #ec543b;

$striped-row-even-background: #EEEEEE;
$striped-row-odd-background: #FFFFFF;
$striped-row-winning-background: #C5EFCD;

$tab-text-color: #000000;
$tab-active-text-color: #ffffff;
$tab-background: #21527d none repeat scroll 0% 0%;

$bar-background-color: #848484;
$bar-text-color: #FFF;

$footer-background-color: #f9f9f9;
$footer-text-color: #999;
$footer-border-top: 1px solid #e5e5e5;

$multipar-map-default-fill: #666;
$multipar-map-default-stroke: #333;
$multipar-map-default-stroke-width: 1;
$multipar-map-default-opacity: 0.6;
$multipar-map-selected-fill: #4b7aa5;
$multipar-map-selected-stroke: #132e46;
$multipar-map-selected-stroke-width: 1;
$multipar-map-selected-opacity: 0.6;
$multipar-map-winning-fill: #7bce8b;
$multipar-map-winning-stroke: #277936;
$multipar-map-winning-stroke-width: 1;
$multipar-map-winning-opacity: 0.6;

$invoice-bar-background: #EAEAEA;
$invoice-bar-color: #000;
$invoice-bar-border-top: 1px solid #CCC;
$invoice-bar-border-bottom: none;
$invoice-bar-border-left: none;
$invoice-bar-border-right: none;

body {
  font-family: $font-family;
  background-color: $background-color;
  position: relative;
  min-height: 100vh;
  padding-bottom: 40rem;

  @media (max-width: 800px) {
    padding-bottom: 90rem;
  }
  @media (max-width: 530px) {
    padding-bottom: 140rem;
  }
}

.container {
  background: $container-background-color;
  margin-bottom: 25px;
  // box-shadow: $container-shadow;
}


.navbar {
  min-height: $navbar-height;

  .container-fluid {
    max-width: $header-max-width;
  }
}

.navbar-brand {
  height: $navbar-height;
  img {
    height: auto;
    max-width: 100%;
  }
}

.flex-right {
  margin-left: auto;
}
.flex-left {
  margin-right: auto;
}
.flex-center-hor {
  margin-left: auto;
  margin-right: auto;
}
.flex-center-vert{
  margin-bottom: auto;
  margin-top: auto;
}
.flex-bottom{
  margin-top:auto;
}
.flex-top{
  margin-bottom: auto;
}

/*.navbar-default {
  background-color: $header-background-color;
  border: none;
  border-radius: 0px;
  margin-bottom: 0px;

  .navbar-nav {
    li>a {
      color: $header-text-color;
      padding-top: 40px;
      padding-bottom: 40px;

      @media (max-width: 768px) {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      &:hover, &:active, &:focus {
        color: $header-text-hover-color;
      }
    }
  }

  .navbar-form {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 34px 0;

    @media (max-width: 768px) {
      margin: 5px 0;
    }

    .input-group {
      width: 300px;
    }

    input[type=text] {
      height: 31px;
      color: $header-text-color;
      background: none;
      border: none;
      border-bottom: 1px solid #CCC;
      border-radius: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .input-group-btn {
      border-bottom: 1px solid #CCC;
      width: 37px!important;
    }

    button {
      color: $header-text-color;
      margin-bottom:-2px;
      background: none;
      border: none;
      border-radius: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;

      &:hover {
        color: $header-text-color;
        font-size: 16px;
        line-height: 14px;
        padding-left:10px;
        background: none;
        border: none;
        border-radius: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .navbar-collapse {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-toggle {
    margin-top: 33px;

    .icon-bar {
      background-color: #ddd;
    }

    &:hover, &:active, &:focus {
      .icon-bar {
        background-color: $header-background-color;
      }
    }
  }
}*/

.breadcrumb {
  margin-top: 20px;
  margin:0;
  box-shadow:none;
  border-radius: 0;
  background-color: #292929;
  li{
    a{
      color:#fff;
      text-decoration: underline;
    }
    &.active{
      color: #fff;
    }
  }
}

.bread-holder {
  width: 100%;
  background-color: #292929;

  .container {
    background-color: #292929;
    padding: 0px;
    box-shadow: none;
  }
}

.winning {
  color: green;
  font-weight: bold!important;
}
.not-winning {
  color: #e6ac40;
  font-weight: bold!important;
}

.search-form {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}


.img-thumbs {
  margin-left: -1px;
  margin-right: -1px;
  display: inline-block;
}

.img-thumbs div {
  padding: 2px 1px;
}

.lot-details {
  padding: 0px;
  position: relative;

  @media (min-width: 992px) {
    height: 407px;
  }
  @media (min-width: 1200px) {
    height: 496px;
  }

  h1 {
    background-color: $bid-box-background-color;
    color: $bid-box-text-color;
    padding: 5px 15px;

    small {
      color: $bid-box-text-color;
      font-size: 12px;
      float: right;
      margin-top: 25px;
    }
  }

  div {
    margin: 0 0px;

    h2 {
      margin: 10px 0;
      font-weight: bold;
    }
    .more-details {
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      justify-content: space-between;
      align-content: stretch;
      margin-bottom: 20px;
      margin-left: 10px;
      background-color: #ebebeb;
      border-radius: 5px;
      padding-top: 10px;
      padding-bottom: 10px;

      &.details-col{
        font-size: 16px;
        padding:0px;
        margin: 0px;
      }
      p {
        margin-bottom: 5px;
      }
    }
    .details-box{
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      justify-content: space-between;
      align-content: stretch;
      margin-bottom: 20px;
      margin-left: 5px;
      margin-right: 25px;
      padding-top: 20px;
    }
    .details-header{
      width: 100%;
      // outline-style: solid;
      // outline-width: 5px;
      // outline-color: #ebebeb;
      // outline-offset: 5px;
    }
    .bid-box {
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      padding-bottom: 10px;
      text-align: right;
    }
    .input-group-addon {
      background: none;
    }
  }
}

.description {
  width: 100%;
  // /* hide text if it more than N lines  */
  // overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  // max-height: 8.0em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  table {
    width: auto;
    max-width: 100%;
  }
}
// .description:before{
//   /* points in the end */
//   content: '...';
//   /* absolute position */
//   position: absolute;
//   /* set position to right bottom corner of block */
//   right: 0;
//   bottom: 0;
// }

// .description:after{
//   /* points in the end */
//   content: '';
//   /* absolute position */
//   position: absolute;
//   /* set position to right bottom corner of text */
//   right: 0;
//   /* set width and height */
//   width: 1em;
//   height: 1em;
//   margin-top: 0.2em;
//   /* bg color = bg color under block */
//   background: #ebebeb;
// }

footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  .title {
      color: #0a4386;
      font-family: 'Monserrat', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 15px;
  }
}

footer > div p:nth-child(1) {
  margin-bottom: 5px;
}
footer > div p:nth-child(2) {
  margin-bottom: 0px;

  *:not(:first-child) {
    margin-left: 4px;
  }
}

.listing-doc {
  border: 1px solid #ececec;
  padding: 15px;
  border-radius: .5em;
  margin: 0 20px 20px 0;
  width: 125px;
  
  &:not(:last-child) {
    margin-right: 10px;
  }

  p {
    margin-top: 20px;
  }
}

.lot-tile {
  border: 1px solid rgba(0, 0, 0, 0);
  position: relative;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 45px;
  display: inline-block;

  h2 {
    margin-bottom: 0px;

    span {
      font-size: 14px;
      margin-top: 16px;
      float: right;
    }
  }
  .lot-description {
    width: 100%;
  }

  margin: 1%;
  width: 98%;
  @media screen and (min-width: 768px){
    margin: 1%;
    width: 48%;
  }
  @media screen and (min-width: 992px){
    margin: 1%;
    width: 23%;
  }
}

// .auction-tile {
//   position: relative;
//   margin-bottom: 20px;
//   padding-top: 5px;
//   padding-bottom: 45px;
//   display: inline-block;

//   h2 {
//     margin-bottom: 0px;
//   }

//   margin: 1%;
//   width: 98%;
//   @media screen and (min-width: 768px){
//     margin: 1%;
//     width: 31%;
//   }
// }

.display-rows {
  .lot-tile { //, .auction-tile
    width: 98%;
    @media screen and (min-width: 768px){
      padding-bottom: 0px;
      h2, div {
        float: left;
        width: 64%;
        margin: 5px 1%;
      }
      .media-main {
        float: left;
        width: 31%;
        margin: 5px 1%;
      }
    }
  }

  @media (min-width: 600px) {
    .auction-tile-two {
      width: 100%
    }

    .flex-two {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding: 0px;

      .item-1 {
        width: 40%;
        a {
          width: 100%;
          img {
            width: 100% !important;
            height: auto;
          }
        }
      }

      .item-2 {
        padding-left: 10px;
        width: 60%;
        display: flex;
        flex-direction: column;
        .auction-description {
          flex-grow: 1
        }
        p {
          align-self: flex-end;
        }
      }

      a.auction-tile-image {
        width: 60%;
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .auction-tile-two:not(:first-child) {
      width: 49%;
      margin-left: 1%;
      @media (max-width: 500px) {
        width: 100%;
        margin: 0;
      }
    }

    .auction-tile-two:first-child {
      width: 49%;
      margin-right: 1%;
      @media (max-width: 500px) {
        width: 100%;
        margin: 0;
      }
    }

    .flex-two {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px;

      .item-1 {
        width: 100%;
        a {
          width: 100%;
          img {
            width: 100% !important;
            height: auto;
          }
        }
      }
      .item-2 {
        padding-left: 10px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        .auction-description {
          flex-grow: 1;
        }
        p {
          align-self: flex-end;
        }
      }
    }
  }
}

.media-list {
  margin: 0 -.5%;

  .media-more {
    pointer-events: none;
    position: absolute;
    background-color: rgba(0,0,0,.35);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;

    span {
      position: absolute;
      margin-top: -10px;
      bottom: 10%;
      right: 10%;
      font-size: 20px;
      line-height: 100%;
      text-align: center;
    }
  }
}

.media {
  width:24%;
  margin: .5%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.media-main {
  cursor: pointer;
  position: relative;

  .openGallery {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px;
    font-size: 23px;
    line-height: 23px;
    color: rgba(255,255,255,0.9);
    text-shadow: 0px 1px 5px #000;
    transition: .1s;

    &:hover {
      font-size: 25px;
      line-height: 25px;
      color: rgba(255,255,255,1);
      text-shadow: 0px 1px 5px #000;
    }
  }
  .openModal {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px;
    font-size: 23px;
    line-height: 23px;
    color: rgba(255,255,255,0.9);
    text-shadow: 0px 1px 5px #000;
  }
}

::-webkit-scrollbar-track{background-color:rgba(0,0,0,0)}::-webkit-scrollbar{width:10px;background-color:rgba(0,0,0,0)}::-webkit-scrollbar-thumb{border:1px solid rgba(0,0,0,0);border-radius:10px;background-color:#939393}::-webkit-scrollbar-corner{background-color:rgba(0,0,0,0)}body,html{scrollbar-arrow-color:#939393;scrollbar-track-color:rgba(0,0,0,0);scrollbar-face-color:#939393;scrollbar-highlight-color:rgba(0,0,0,0);scrollbar-3dlight-color:rgba(0,0,0,0);scrollbar-shadow-color:rgba(0,0,0,0);scrollbar-darkshadow-color:rgba(0,0,0,0)}

#countdown div {
  display: inline-block;
  width: 65px;
  border: 2px solid #4A4A4A;
  border-radius: 10px;
  margin: 5px;

  strong {
    font-size: 42px;
    display: inline-block;
    width: 100%;
    height: 50px;
  }

  span {
    font-size: 10px;
    display: inline-block;
  }
}

.nav-tabs {
  border-bottom: 2px solid #DDD;
  margin-bottom: 10px;
  li.active {
    background: $accent-background-color;
    color: $accent-text-color;
    border: solid $accent-background-color 2px;
    border-radius: 3px;

    a, a:focus, a:hover {
      border-width: 0;
      background: $accent-background-color;
      color: $accent-text-color;
    }
    a, a:hover {
      border: none;
      color: $tab-active-text-color !important;
      background: transparent;
    }
    a::after {
      transform: scale(1);
    }
  }
  li {
    a {
      border: solid #f0f0f0 1px;
      color: $tab-text-color;
      // &::after {
      //   content: "";
      //   background: $tab-active-text-color;
      //   height: 2px;
      //   position: absolute;
      //   width: 100%;
      //   left: 0px;
      //   bottom: -1px;
      //   transition: all 250ms ease 0s;
      //   transform: scale(0);

      // }
    }

    &:hover > a::after {
      transform: scale(1);
    }
  }
}

.tab-nav > li > a::after {
  background: $tab-background;

  color: #fff;
}

.tab-pane {
  padding: 15px 0;
}

.tab-content {
  padding: 20px;

  .active {
    z-index: 100;
  }
}

#searchResults .description {
  max-height: 60px;
  overflow: hidden;
}

.watchDiv {
  float: left;
  margin: 0;
  display: inline-block;

  .watch {
    color: $watch-color;
    font-size: 18px;
    padding: 3.5px 11px;
    line-height: 20px;


  }
  span {
    margin-left: 5px;
  }
}

.watch{
  &:hover .fa-eye {
    color: $watch-hover-color;
  }
  &.watched .fa-eye {
    color: $watch-watched-color;
  }
  &.watched:hover .fa-eye {
    color: $watch-watched-hover-color;
  }
}

.lotButtonsBottom {
  position: absolute;
  bottom: 9px;
  right: 14px;
}
.lotButtons .watch {
  line-height: 20px;
  font-size: 18px;
  color: $btn-watch-color;
  width: 20%;

  &:hover {
    color: $btn-watch-hover-color;
  }
  &.watched {
    color: $btn-watch-watched-color;
  }
  &.watched:hover {
    color: $btn-watch-watched-hover-color;
  }
}

.striped-row {
  padding: 5px 0;

  &:nth-child(odd){
    background: $striped-row-odd-background;
  }
  &:nth-child(even){
    background: $striped-row-even-background;
  }
  &.winning-row{
    background: $striped-row-winning-background;
  }
  &.selected{
    background: #337ab7 !important;
    color: #fff;
    a { color: #fff !important; }
  }

}

.striped-row:nth-child(even) {
  &.winning-row{
    background: darken($striped-row-winning-background, 5%);
  }
  &.selected{
    background: darken(#337ab7, 5%) !important;
  }
}


.row.header {
  background: darken($bar-background-color, 25%);
  color: $bar-text-color;
}

.bar {
  background: $bar-background-color;
  color: $bar-text-color;
  padding: 5px 14px;
  margin: -5px -15px 0 -15px;
  a {
    margin-top: 4px;
    color: $bar-text-color;
    font-size: 14px;
    cursor: pointer;

    i.fa:before { content: "\f077"; }
    &.collapsed i.fa:before { content: "\f078"; }
  }
}

#image-map {
  max-width:100%;
  position: relative;
  display:inline-block;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .coord-obj {
      fill: $multipar-map-default-fill;
      stroke: $multipar-map-default-stroke;
      stroke-width: $multipar-map-default-stroke-width;
      opacity: $multipar-map-default-opacity;
      cursor: pointer;
    }

    .coord-obj.winning {
      fill: $multipar-map-winning-fill;
      stroke: $multipar-map-winning-stroke;
      stroke-width: $multipar-map-winning-stroke-width;
      opacity: $multipar-map-winning-opacity;
    }
    .coord-obj.selected {
      fill: $multipar-map-selected-fill;
      stroke: $multipar-map-selected-stroke;
      stroke-width: $multipar-map-selected-stroke-width;
      opacity: $multipar-map-selected-opacity;
    }
  }
}

.invoice-bar{
  background: $invoice-bar-background;
  padding:5px;
  color: $invoice-bar-color;
  border-top: $invoice-bar-border-top;
  border-bottom: $invoice-bar-border-bottom;
  border-left: $invoice-bar-border-left;
  border-right: $invoice-bar-border-right;
  margin-top:5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
}

.container.extended-layout{
  display: flex;
  @media screen and(min-width: 1600px){
    width: 1670px !important;
  }
  >.lot-flex{
    @media screen and(min-width: 1600px){
      width: 23%;
    }
  }
}

.lot-flex{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  &.tile-view{
    .item{
      background-color: #f2efee;
      padding: 10px;
      margin: 0 7px 10px;
      width: 330px;
      display: inline-flex;
      flex-direction: column;

      transition: transform 350ms;

      &.newBid {
        .bidAmount {
          color: #059416;
          animation: 6s ease-out bidflash;
        }
      }

      &.blank{
        box-shadow: none !important;
        background: #fff;
      }
      &:hover {
        transform: scale(1.03);
       // box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.75);
       // transition: box-shadow 0.5s ease-in-out


      }

      @media screen and (min-width: 768px){
        width: 47%;
        margin-bottom: 40px;
        &.featured_tile{
          width: 95%;
        }
      }
      @media screen and (min-width: 992px){
        width: 31%;
        &.featured_tile{
          width: 45%;
        }
      }
      @media screen and(min-width: 1600px){
        width: 23%;
      }
      &.featured_tile{
        .content{
          display: flex;
          flex-direction: row;
        }
        .info_div{

        }
        .bid-box{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 1 0 auto;
        }
      }
      .lot-picture{
        width: 100%;
      }
      .details{
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .action-btn {
        font-size: 13px;
        font-weight: bold;
        background-color: #000;
        color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

  }
  &.list-view{
    .item{
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin-bottom: 10px;
      @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 48%;
      }
      .lot-picture{
        width: 260px;
        margin-right: 16px;
        @media screen and (max-width: 768px){
          margin-right: 0;
          width: 100%;
        }
      }
      .details{
        flex-grow: 1;
        @media screen and (max-width: 768px){
          width: 100%;
          flex-grow: 0;
        }
      }

    }
  }
}

.lot-button{
  background-color: #000000;
  border-radius: 0px;
  color: #ffffff;
}

.sidebar-header{
  width: 100%;
  background: $accent-background-color;
  color: #FFFFFF;
  text-align: center;
  padding: 3px;
  vertical-align: middle;
  &.open{
    >h1>i{
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  }
  >h1>i{
    -moz-transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    transform-origin: center;
    transform-style: preserve-3D;
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.side-info{
  background: #E9EBEA;
}

.table {
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa;
}

.scrollTable{
  display: table;
  margin-top: 10px;
  margin-bottom: 10px;
}
.scrollTable thead, .scrollTable tbody{
  float: left;
  width: 100%
}
.scrollTable tbody{
  overflow: auto;
  height: 200px;
}

.scrollTable tr{
  width: 100%;
  display: table;
  text-align: left;
}

.clickable-row:hover {
  background-color: $accent-background-color;
  color: #ffffff;
}
.clickable-row{
  background: #fafafa;
}

.btn-bottom {
  background-color: #6d6d6d;
  color: #fff;

  &:hover {
    background-color: #797979;
    color: #fff;
  }
}

.btn-square {
  border-radius: 0;
}

.text-upper {
  text-transform: uppercase;
}

#page-nav {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;

  .container-fluid {
    max-width: 1200px;
  }

  nav#user-menu {
    order: 1;
    width: 100%;

    &.navbar-collapse {
      border-color: #e7e7e7;
    }

    .navbar-nav > li > a {
      color: #777;
    }

    @media (min-width: 768px) {
      order: 0;
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      .navbar-nav > li > a {
        line-height: 12px;
      }
    }
  }

  nav#main-menu ul {

    li > a {
      color: #000;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
    }
  }

  nav {
    .nrml {
      font-weight: normal;
    }
  }

  .navbar {
    margin-bottom: 0px;
    border: none;
    background-color: #fff;

    .container-fluid {
      @media (min-width: 768px) {
        display: flex;
        nav {
          flex: 1 0 auto;
          ul {
            width: 100%;
          }
        }
      }
      .navbar-header {
        display: flex;

        & > * { float: none; order: 1; }

        .navheader-left { order: 0; }
        .navheader-right { order: 2; }
        .navbar-toggle {
          margin-left: 15px;
          align-self: center;
          min-width: 40px;
          min-height: 40px;
        }
        .navbar-brand {
          flex: 1 1 auto;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.snd-container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
#link-footer {
  .footer-content{
    padding: 50px 50px 0;
    background-color: #d7d7d7;
    color: #000;
    display: table-cell;

    .about-us-footer {
      margin-bottom: 20px;
    }

    .footer-container-fluid {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;

      ul {
        list-style-type: none;
        flex-basis: 100%;
        margin-bottom: 20px;

        li {
          a {
            display: inline-block;
            padding-top: 7px;
            padding-bottom: 5px;
            font-family: 'Monserrat', sans-serif;
            font-size: 12px;
            color: #000;
            text-transform: uppercase;
          }
        }
      }

      @media screen and (min-width: 800px) {
        ul {
          flex: 1;
        }
      }
    }
  }
}
.footer-copyright-container {
  margin-top: 50px;
  border-top: 1px solid #000;

  .footer-copyright {
    padding: 20px;
    text-align: center;
  }
}

.rl-home {
  h2 {
    font-family: 'Monserrat', sans-serif;
    font-size: 14px;
    color: #898e8f;
    text-transform: uppercase;
    margin-bottom: 30px;

    span {
      display: table;
      font-size: 32px;
      font-weight: bold;
      color: #343a3b;
    }
  }
}

.home-title {
  font-size: 21px;
  text-transform: uppercase;
  margin-top: 0px;
}

.display-row {
  .auction-tile {
    display: flex;

    .auction-image {
      display: block;
      height: 200px;
      width: 250px;
      line-height: 200px;
      flex: 0 0 auto;
      border: 1px solid #ededed;
      text-align: center;

      img {
        vertical-align: middle;
        display: inline-block;
        max-height: 198px;
      }
    }
    @media (max-width: 520px) {
      flex-direction: column;
    }

    @media (max-width: 767.9px) {
      .auction-image {
        height: 120px;
        width: 150px;
        line-height: 120px;

        img {
          max-height: 118px;
        }
      }
    }

    .auction-flex {
      flex: 1 1 auto;
      display: inline-flex;
      flex-direction: column;

      .auction-actions {
        align-self: flex-end;
        white-space: nowrap;
      }
    }

    & + .auction-tile {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #6d6d6d;
    }
  }
}

.display-tile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .auction-tile {

    background-color: #f2efee;
    padding: 10px;
    margin: 0 7px 10px;
    width: 330px;
    display: inline-flex;
    flex-direction: column;

    transition: transform 250ms;

    &:hover {
      transform: scale(1.05);
      z-index: 10000;
    }

    .auction-flex {
      display: inline-flex;
      flex-direction: column;
      height: 100%;
    }

    .auction-details, .auction-image {
      display: block;
      /*border-left: 2px solid #e9e9e9;
      border-right: 2px solid #e9e9e9;*/
    }
    .auction-details {
      font-size: 12px;
      line-height: 17px;
      color: #999;
      padding-top: 20px;
      padding-bottom: 20px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;

      h3 {
        font-family: "Myriad Pro", Myriad, "Myriad Web Pro", "PT Sans", sans-serif;
        font-size: 16px;
        color: #000;
        margin-top: 0;
        text-transform: uppercase;
        text-decoration: underline;
      }

      .sub-title {
        font-family: "Myriad Pro", Myriad, "Myriad Web Pro", "PT Sans", sans-serif;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: -1px;
        color: #0a4386;
      }

      .desc {
        margin-top: 10px;
        background-color: #fff;
        color: #737475;
        padding: 5px;
        font-size: 15px;
        flex: 1;
      }
    }

    .auction-actions {
      display: flex;

      div {
        margin: 5px;
        width: 100%;
      }

      .enter-auction {
        display: block;
      //   background-color: #000;
      //   color: #fff;
      //   text-decoration: none;
      //   text-transform: uppercase;
      //   padding-top: 10px;
      //   padding-bottom: 10px;
      }

      .action-btn {
        font-size: 13px;
        font-weight: bold;
        background-color: #000;
        color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.full-auctions {

  text-align: center;
  margin: 20px;

  .btn{
    background-color: #0a4386;
    color: #FFF;
    font-family: 'Monserrat', sans-serif;
    font-size: 14px;
    border-radius: 20px;
    padding: 10px 25px;
    margin: 10px;

    &:hover {
      background-color: #08356a;
      transform: scale(1.05);
    }
  }
}

.wwd-container {
  background-color: #0a4386;
  padding-bottom: 100px;

  h2 {
    color: #FFF;

    span {
      color: #FFF;
    }
  }

  .container {
    background: none;

    .tiles {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      a:hover {
        text-decoration: none;
      }

      .tile {
        margin: 0 0 10px;
        //background-color: #fff;

        > a, > div {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        ul{
          list-style-type: none;
          padding-left: 15px;
        }
      }

      .tile-img {
        background-size: cover;
        min-height: 189px;
      }

      .tile-title {
        background-color: #6f90b8;
        color: #fff;
        font-family: 'Monserrat', sans-serif;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
      }

      .tile-desc {
        padding: 10px;
        font-weight: bolder;
        font-size: 12px;
        background-color: #fff;
        flex: 1;
      }
    }
  }
}

.fa-container {
  background-color: #cbccce;
  padding-bottom: 50px;

  h2 {
    color: #FFF;

    span {
      color: #FFF;
    }
  }

  .container {
    background: none;

    input {
      padding: 25px;
      font-weight: bold;
    }

    .btn-submit {
      background-color: #000;
      color: #fff;
      font-family: 'Monserrat', sans-serif;
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      border-radius: 0;
      padding: 10px;
      width: 100%;
    }
  }
}

.cu-container {
  padding-bottom: 50px;

  .tile {
    border: 1px solid #e1eaec;
    padding: 30px 5px;
    margin-bottom: 10px;

    .tile-header {
      font-family: 'Monserrat', sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      text-transform: uppercase;
    }

    .tile-content {
      font-family: 'Monserrat', sans-serif;
      font-size: 14px;
      color: #000;
    }

  }
}

.p-l-10 {
  padding-left: 10%;
}
.carousel-caption {
  bottom: auto;
  top: 0px;
  padding-top: 0px;


  @media screen and (min-width: 768px) {
    top: 10px;
    padding-top: 20px;
  }

  @media screen and (min-width: 992px) {
    top: 20px;
  }

  @media screen and (min-width: 1150px) {
    top: 50px;
  }
}

#carousel-search {
  position: absolute;
  top: 50%;
  left: 15%;
  right: 15%;
  z-index: 11;

  @media screen and (min-width: 768px) {
    left: 20%;
    right: 20%;
  }

  .form-group, .input-group {
    width: 100%;
    .input-group-btn {
      width: 1%;
    }
  }
}

